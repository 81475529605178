import { LanguageActions, LanguageState } from "../../types/store/language";
import * as Action from "@actions/index";

const InitialState: LanguageState = {
  status: "waiting",
  selected: "ru",
  list: [],
  data: {},
  error: "",
};

const Language = (state: LanguageState = InitialState, action: LanguageActions): LanguageState => {
  switch (action.type) {
    case Action.Language.SET_LIST:
      return { ...state, list: action.data };
    case Action.Language.SET_SELECTED:
      return { ...state, selected: action.data };
    case Action.Language.SET_DATA:
      return { ...state, data: action.data };
    case Action.Language.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Language.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Language.CLEAR:
      return { ...state, list: [] };
    default:
      return state;
  }
};

export default Language;
