import { LanguageActions } from "../../types/store/language";

export default class Language {
  public static readonly SET_SELECTED = "LANGUAGE_SET_SELECTED";
  public static readonly SET_LIST = "LANGUAGE_SET_LIST";
  public static readonly SET_DATA = "LANGUAGE_SET_DATA";
  public static readonly SET_STATUS = "LANGUAGE_SET_STATUS";
  public static readonly SET_ERROR = "LANGUAGE_SET_ERROR";
  public static readonly CLEAR = "LANGUAGE_CLEAR";

  public static setSelected(data: string): LanguageActions<string> {
    return {
      data,
      type: Language.SET_SELECTED,
    };
  }

  public static setData(data: unknown): LanguageActions<unknown> {
    return {
      data,
      type: Language.SET_DATA,
    };
  }

  public static setList(data: string[]): LanguageActions<string[]> {
    return {
      data,
      type: Language.SET_LIST,
    };
  }

  public static setStatus(data: string): LanguageActions<string> {
    return {
      data,
      type: Language.SET_STATUS,
    };
  }

  public static setError(data: string): LanguageActions<string> {
    return {
      data,
      type: Language.SET_ERROR,
    };
  }

  public static clear(): Omit<LanguageActions<string>, "data"> {
    return {
      type: Language.CLEAR,
    };
  }
}
