import * as Action from "@actions/index";
import moment from "moment-timezone";
import type { IBroadcast, IUser } from "@interface/entities";
import { Store, useDispatch } from "@helpers/Store";

const InitialState = {
  status: "waiting",
  list: null,
  modifiedList: null,
  error: null,
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export default function BroadcastsSchedule(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Broadcasts.Schedule.SET_LIST:
      const networkingDays: string[] = [
        "2021-11-16T09:00:00+03:00",
        // "2021-11-13T09:00:00+03:00",
        // "2021-11-14T09:00:00+03:00",
        // "2021-11-15T09:00:00+03:00",
        // "2021-11-16T09:00:00+03:00",
      ];

      // const networkingDays: string[] = [];

      const schedule: any = {};

      action.data?.forEach((broadcast: IBroadcast) => {
        const dateFormat: string = moment(broadcast.startAt)
          .tz("Europe/Moscow")
          .format("YYYY-MM-DDT08:00:00+03:00");

        if (schedule.hasOwnProperty(dateFormat)) {
          schedule[dateFormat].push(broadcast);
        } else {
          schedule[dateFormat] = [];
          schedule[dateFormat].push(broadcast);
        }
      });

      networkingDays.forEach((date: string, index: number) => {
        const item: IBroadcast = {
          id: 13371377 + index,
          companyTitle: null,
          youtubeUrlId: null,
          title: "NetworkingDay",
          startAt: date,
          endAt: moment(date).add("9", "hours").tz("Europe/Moscow").format("YYYY-MM-DDTHH:mm:ssZ"),
          description: null,
          subscriberList: [],
          speakerList: [],
          isNetworkingDay: true,
        };

        if (schedule.hasOwnProperty(date)) {
          schedule[date].push(item);
        } else {
          schedule[date] = [];
          schedule[date].push(item);
        }
      });

      return { ...state, list: action.data, modifiedList: schedule };
    case Action.Broadcasts.Schedule.SET_MODIFIED_LIST:
      return { ...state, modifiedList: action.data };
    case Action.Broadcasts.Schedule.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Broadcasts.Schedule.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Broadcasts.Schedule.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
