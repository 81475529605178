import * as Action from "@actions/index";

const InitialState = {
  timezone: {
    main: {
      format: "Etc/UTC",
      name: {
        en: "UTC",
        ru: "UTC",
        de: "UTC",
        es: "UTC",
        fr: "UTC",
      },
    },
    msk: {
      format: "Europe/Moscow",
      name: {
        en: "MSK",
        ru: "МСК",
        de: "MZ",
        es: "MSK",
        fr: "MSK",
      },
    },
  },
  dates: {
    registrationStart: "",
    broadcastsStart: "2021-10-04T09:00:00+03:00",
    registrationEnd: "",
    networkingDays: [
      "2021-11-12T09:00:00+03:00",
      "2021-11-13T09:00:00+03:00",
      "2021-11-14T09:00:00+03:00",
      "2021-11-15T09:00:00+03:00",
      "2021-11-16T09:00:00+03:00",
    ],
  },
  isCabinetOpened: true,
  languages: ["en", "ru", "de", "fr", "es"],
  // error: "",
};

export default function Event(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Event.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Event.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Event.CLEAR:
      return { ...state, ...InitialState };
    default:
      return state;
  }
}
